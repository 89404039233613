const Days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
const DaysFromSunday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const DaysAbbrev = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
const OrdinalsAbbrev = ['', '1st', '2nd', '3rd', '4th', '5th', '6th']
//// TODO get these from the backend
const stateOptions = [
  { label: "Alaska", value: "AK" },
  { label: "Alabama", value: "AL" },
  { label: "Arkansas", value: "AR" },
  { label: "Arizona", value: "AZ" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "District of Clumbia", value: "DC" },
  { label: "Delaware", value: "DE" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Hawaii", value: "HI" },
  { label: "Iowa", value: "IA" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Massachusetts", value: "MA" },
  { label: "Maryland", value: "MD" },
  { label: "Maine", value: "ME" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Missouri", value: "MO" },
  { label: "Mississippi", value: "MS" },
  { label: "Montana", value: "MT" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Nebraska", value: "NE" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "Nevada", value: "NV" },
  { label: "New York", value: "NY" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Virginia", value: "VA" },
  { label: "Vermont", value: "VT" },
  { label: "Washington", value: "WA" },
  { label: "Wisconsin", value: "WI" },
  { label: "West Virginia", value: "WV" },
  { label: "Wyoming", value: "WY" }
]

const accessibleAreaIds = function() {
  if (Window.area_ids) {
    return Window.area_ids
  }
  return []
}
const dateToAppFormat = function(date) {
  const format = 'yyyy-MM-dd'
  if (typeof(date) == 'string') {
    return Date.parse(date).toString(format)
  }
  if (typeof(date) == 'object') {
    return date.toString(format)
  }
  console.error('Invalid date: ', date)
}

const timeToAppFormat = function(time) {
  const format = 'yyyy-MM-dd HH:mm'
  if (typeof(time) == 'string') {
    return Date.parse(time).toString(format)
  }
  if (typeof(time) == 'object') {
    return time.toString(format)
  }
  console.error('Invalid time: ', time)
}

const formatCurrency = function(amount) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    useGrouping: false
  })
  return formatter.format(amount)
}

const formatAddress = function({ address, address2, city, state, country, zip_code }) {
  const addressPart = [ address, address2 ].filter(element => {
    return element !== null && element !== undefined && element.length > 0;
  }).join(' ');

  const stateAndZip = [ state, zip_code ].filter(element => {
    return element !== null && element !== undefined && element.length > 0;
  }).join(' ')

  const full = [ addressPart, city, stateAndZip, country ].filter(element => {
    return element !== null && element !== undefined && element.length > 0;
  }).join(', ');

  return { full: full, addressPart: addressPart }
}

const formatAddressNoSuite = function({ address, city, state, country, zip_code }) {
  return formatAddress({ address: address, city: city, state: state, country: country, zip_code: zip_code })
}

import Calendar from './calendar'
// weeks starting on Monday
const cal = new Calendar(1);

const onlyUnique = function(value, index, self) {
  return self.indexOf(value) === index
}

const savedExtras = function(allExtras) {
  var extras = []
  Array.prototype.forEach.call(allExtras, (extra) => {
    if (extra.add) {
      extras.push(extra)
    }
  })
  return extras
}

const isCarpetCleaning = function(event) {
  return event.reservation_type == 'CarpetCleaning'
}

const isPropertyCleaning = function(event) {
  return event.reservation_type == 'PropertyCleaning'
}

const getObjByValue = (obj, value) => {
  if (value != null) {
    const x = obj.find(obj => obj.value === value)
    if (x) {
      return x
    }
  }
  return { label: '', value: null }
}

const getObjById = (obj, value) => {
  if (value != null) {
    const x = obj.find(obj => obj.id === value)
    if (x) {
      return x
    }
  }
  return null
}

const daysInWeek = (week) => {
  var days = []
  for (var i = 0; i < 7; i++) {
    var dayInfo = { monthlyIdx: 0, weeklyIdx: 0 }
    if (week[i] > 0) {
      dayInfo.monthlyIdx = week[i]
      dayInfo.weeklyIdx = i
    }
    days.push(dayInfo)
  }
  return days
}

const pickedDayInfoFromStart = (start, frequency, repeat_by, repeat_on) => {
  if ( (start == null) || (start == '') ) {
    return
  }
  var parsedDate = Date.parse(start)
  var info = { monthlyIdx: parsedDate.getDate() }
  var weeks = []
  var m = cal.monthDays(parsedDate.getFullYear(), parsedDate.getMonth())
  for (var i = 0; i < m.length; i++) {
    weeks.push(m[i])
  }
  for (var weekIdx in weeks) {
    if (weeks[weekIdx].includes(parsedDate.getDate())) {
      const sunBasedDay = parsedDate.getDay()
      var weekDay = sunBasedDay
/*      if (sunBasedDay == 0) {
        weekDay = 7
      }
*/
      info.dayInWeek = weekDay
      info.weekIdx = (weekIdx * 1) + 1
    }
  }

  if (frequency == 'weekly') {
    if (repeat_on != null) {
      info.dayInWeek = repeat_on
    }
  }

  return info
}

const dynamicSort = (property) => {
    var sortOrder = 1;

    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }

    return function (a,b) {
        if(sortOrder == -1){
            return b[property].localeCompare(a[property]);
        }else{
            return a[property].localeCompare(b[property]);
        }
    }
}
//const getObjByLabel = (obj, label) => obj.find(obj => obj.label === label)
//const getObjByKey = (obj, value) => Object.keys(obj).find(key => obj[key] === value)

const getBrowserLocale = () => {
  const navigatorLocale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language

  if (!navigatorLocale) {
    return undefined
  }

  return navigatorLocale.trim().split(/-|_/)[0]
}

const paymentPeriodOptions = () => {
  return paymentPeriods().map((e) => { return { label: `${e[0].toString('d')} - ${e[1].toString('d')}`, value: `${e[0].toString('yyyy-MM-dd')} - ${e[1].toString('yyyy-MM-dd')}` } })
}

const paymentPeriods = () => {
  const numDays = 4;
  let periods = []
  let start = Date.monday().add(7 * numDays * -1).days()
  for (let i = numDays * -1; i < 0; i++) {
    let end = Date.parse(start).add(6).days()
    periods.push([new Date(start), new Date(end)])
    start.add(7).days()
  }
  return periods
}

const  isAdmin = null;
const  isAgent = null;
const  isClient = null;
const  isCleaner = null;
const  isGlobalAdmin = null;

export {
  cal,
  dateToAppFormat,
  timeToAppFormat,
  Days,
  DaysFromSunday,
  DaysAbbrev,
  OrdinalsAbbrev,
  accessibleAreaIds,
  onlyUnique,
  savedExtras,
  getObjByValue,
  getObjById,
  daysInWeek,
  pickedDayInfoFromStart,
  dynamicSort,
  stateOptions,
  formatCurrency,
  isPropertyCleaning,
  isCarpetCleaning,
  getBrowserLocale,
  formatAddress,
  formatAddressNoSuite,
  paymentPeriods,
  paymentPeriodOptions,
  isAdmin,
  isAgent,
  isClient,
  isCleaner,
  isGlobalAdmin
};

import Vue from 'vue'
Vue.filter('toCurrency', function (value) {
    if (typeof value !== "number") {
        return value
    }
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    });
    return formatter.format(value / 100);
})
